import React from 'react'
import FormOne from '../components/blog/FormOne';
import Footer from '../components/footer/Footer';
import DatosSuport from '../components/blog/DatosSuport';


const PageContact = () => {
 

  return (
    <>
      <FormOne /> 
      <DatosSuport />
      <Footer />
    </>
  );
}

export default PageContact;
