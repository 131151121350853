import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 750px;
  position: relative;
  z-index: 1;

  :before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg, 
      rgba(0, 0, 0, 0.2) 0%, 
      rgba(0, 0, 0, 0.6) 100%), 
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const HeroBg =styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const ImagBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  right: 35px;
  padding: 2px 2px;
  display: flex;
  flex-direction: column;
  //align-items: right ;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: right;

  @media screen and (max-width: 768px){
    font-size: 40px;
  }

  @media screen and (max-width: 480px){
    font-size: 32px;
  }
`;

export const HeroH2 = styled.h2`
  color: #fff;
  font-size: 35px;
  text-align: right;

  @media screen and (max-width: 768px){
    font-size: 25px;
  }

  @media screen and (max-width: 480px){
    font-size: 17px;
  }
`;

export const HeroH3 = styled.h3`
  color: #fff;
  font-size: 25px;
  text-align: right;

  @media screen and (max-width: 768px){
    font-size: 20px;
  }

  @media screen and (max-width: 480px){
    font-size: 12px;
  }
`;

//Estilos de Parrafo
export const HeroP = styled.p`
  margin-top: 2px;
  color: #fff;
  font-size: 17px;
  text-align: right;
 
  @media screen and (max-width: 768px){
    font-size: 14px;
  }

  @media screen and (max-width: 480px){
    font-size: 10px;
  }
`;

//Estilos del boton
export const HeroBtnWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
`;

export const ArrowFroward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;