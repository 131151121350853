import styled from "styled-components";
import Porta2 from '../../images/foa02.JPG'

export  const PagosContainer = styled.div`
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image:linear-gradient(rgb(103, 58, 183, 0.7),rgb(81, 45, 168, 0.7)), url(${Porta2});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    @media screen and (max-width: 768px) {
        height: 1200px;
    }

    @media screen and (max-width: 480px) {
        height: 1300px;
    }
    
`;

export  const PagosTitle = styled.h1`
    color: #fff;
    font-size: 50px;
    margin: 20px;
    align-items: center;
    grid-gap: 30px;
    padding: 0 50px;
`;

export  const PagosWapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
`;

export  const PagosContent = styled.div`
   margin-left: 50px;
   align-items: center;
    @media screen and (min-width:768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

`;

export  const PagosH2 = styled.h2`
    color: #FF8C00;
    font-size: 40px;
    
`;

export  const SpanP = styled.span`
    color: #fff;
`;

export  const PagosP = styled.p`
    background: #65447c;
    
    color: #fff;
    margin: 10px;
    font-size: 18px;
    border: 2px solid;
    padding: 15px;
    border-radius: 1.5em;
`;

export const SpanTP = styled.span`
    color: #FF8C00;
`;

