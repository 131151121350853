import React from 'react';
import InfoSection from '../components/infoSection';
import Footer from '../components/footer/Footer'
import { aboutObjOne, aboutObjThree, aboutObjTwo } from '../components/infoSection/Data';


const About = () => {


  return (
    <>
      <InfoSection {...aboutObjThree} />
      <InfoSection {...aboutObjTwo} />
      <InfoSection {...aboutObjOne} />
      <Footer />
      
    </>
  );
}

export default About;
