import styled from "styled-components";
import {Link} from 'react-scroll';
import {Link as LinkR} from 'react-router-dom';

export const Button = styled(Link)`
    border-radius: 20px;
    background: ${({primary}) => (primary ? '#FF8C00': '#891d88')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px': '12px 30px')};
    color: ${({dark}) => (dark ? '#fff': '#891d88')};
    font-size: ${({fontBig}) => (fontBig ? '20px': '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover{
      transition: all 0.2s ease-in-out;
      background: ${({primary}) => (primary ? '#891d88': '#fff')};
    }
`;

export const Button1 = styled(LinkR)`
    border-radius: 20px;
    text-decoration: none;
    background: ${({primary}) => (primary ? '#FF8C00': '#891d88')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px': '12px 30px')};
    color: ${({dark}) => (dark ? '#fff': '#891d88')};
    font-size: ${({fontBig}) => (fontBig ? '20px': '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover{
      transition: all 0.2s ease-in-out;
      background: ${({primary}) => (primary ? '#891d88': '#fff')};
    }
`;