import React from 'react';
import fIcon from '../../images/foac.png';
import {FaFacebook} from 'react-icons/fa' //FaLinkedin, FaYoutube, FaTwitter,FaInstagram 
import {HiOutlineMail} from 'react-icons/hi';
import {IoMdSend} from 'react-icons/io';
import {FooterContainer,
  FooterWrap, FooterCol,
  FooterImg, FooterP,
  FooterPl, FooteH3, 
  FotterConLink, 
  FooterLink, FooteInput,
  SocialIcons, 
  SocialIconLink,
  IconBtn, FooterForm, Fhr,
  WebSiteTights }from './FooterElements.js'

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterCol>
            <FooterImg src={fIcon} />
            <FooterP>
              Contáctanos en nustros locales y número de celular, redes sociales, correo
              <br/>
              Lima Av. 28 de Julio Nro.757 | Número de Ventas FOA 938 326 319 
              | Huancayo ventas@foa.com.pe
            </FooterP>
          </FooterCol>
          <FooterCol>
            <FooteH3>Informacion</FooteH3>
            <FooterPl to='/precios_promociones'>Tarifas y Promaciones</FooterPl>
            <FooterPl to='/pago_servicios' >Pagos de Servicio</FooterPl>
            {/*<FooterPl to='/' >Informacion de Abonados y Usuarios</FooterPl>
            <FooterPl to='/' >Politcas de Seguridad y salud en Trabajo</FooterPl>*/}
            <FooterPl to='//fast.com/es/' target="-_blank" >Test Velocidad</FooterPl>
          </FooterCol>

          <FooterCol>
            <FooteH3>Navega</FooteH3>
            <FotterConLink>
              <FooterLink to='/' >Home</FooterLink>
              <FooterLink to='/nosotros' >Nosotros</FooterLink>
              <FooterLink to='/precios_promociones' >Planes</FooterLink>
              <FooterLink to='/pago_servicios' >Forma de Pago</FooterLink>
              <FooterLink to='/contactanos' >Contactanos</FooterLink>
            </FotterConLink>
          </FooterCol>

          <FooterCol>
            <FooteH3>Nuetras Redes Sociales</FooteH3>
            <FooterForm>
              <SocialIconLink> <HiOutlineMail /> </SocialIconLink>
              <FooteInput type='email' placeholder='ejemplo@email.com'  />
              <IconBtn to='/contactanos'> <IoMdSend  /> </IconBtn>
            </FooterForm>
            
            <SocialIcons>
              <SocialIconLink href="//www.facebook.com/FibraOpticaAmazonica" target="-_blank" aria-label="Facebook" > <FaFacebook/> </SocialIconLink>
              {/**<SocialIconLink href="//www.linkedin.com/" target="-_blank" aria-label="LinkedIn" > <FaLinkedin/> </SocialIconLink>
              <SocialIconLink href="//www.youtube.com/channel/UCz8ZfnJCXLp8gaYvi-y5lwQ" target="-_blank" aria-label="Youtube" > <FaYoutube/> </SocialIconLink>
              <SocialIconLink href="//www.twitter.com" target="-_blank" aria-label="Twitter" >  <FaTwitter/> </SocialIconLink>
              <SocialIconLink href="//www.instagram.com" target="-_blank" aria-label="Instagram" > <FaInstagram/> </SocialIconLink>
              */}
            </SocialIcons>
          
          </FooterCol>
        </FooterWrap>
        <Fhr/>
        <WebSiteTights>Fibra Optica Amazónica © {new Date().getFullYear()} All Rights Reserved. </WebSiteTights>
      </FooterContainer>
    </>
  );
}

export default Footer;