import React from 'react';
import Iconv1 from '../../images/vfibra.png';
import Iconv2 from '../../images/vexclusive.png';
import Iconv3 from '../../images/vvelocidad.png';
import Iconv4 from '../../images/vsoporte.png';

import { ProjectsContainer, 
    ProjectsH1, 
    ProjectsWrapper, 
    ProjectsCard, ProjectsIcon, ProjectsH2, ProjectsP } from './ValorElements';

const SectionValor = () => {
  return (
    <>
      <ProjectsContainer id="valordd">
            <ProjectsH1>Nuesto Valor Diferencial</ProjectsH1>
            <ProjectsWrapper>
                <ProjectsCard>
                    <ProjectsIcon src={Iconv1} />
                    <ProjectsH2>Tecnología</ProjectsH2>
                    <ProjectsP>Conexión 100% Fibra Óptica de inicio a fin.</ProjectsP>
                </ProjectsCard>
                <ProjectsCard>
                    <ProjectsIcon src={Iconv2} />
                    <ProjectsH2>Exclusividad</ProjectsH2>
                    <ProjectsP>Somos los únicos con Fibra Óptica en Iquitos.</ProjectsP>
                </ProjectsCard>
                <ProjectsCard>
                    <ProjectsIcon src={Iconv3} />
                    <ProjectsH2>Velocidad</ProjectsH2>
                    <ProjectsP>Descarga y subida de datos en segundos.</ProjectsP>
                </ProjectsCard>
                <ProjectsCard>
                    <ProjectsIcon src={Iconv4} />
                    <ProjectsH2>Soporte</ProjectsH2>
                    <ProjectsP>Servicio técnico propio las 24x7.</ProjectsP>
                </ProjectsCard>
            </ProjectsWrapper>
        </ProjectsContainer>
    </>
  )
}

export default SectionValor;
