import React from 'react';
import { PagosContainer, 
    PagosTitle,
    PagosWapper,
    PagosContent,
    PagosH2, SpanP,
    PagosP,SpanTP, } from './PagosElements';

const Pagos = () => {
  return (
    <>
      <PagosContainer>
        
        <PagosTitle>Formas de Pagos</PagosTitle>

        <PagosWapper>
            <PagosContent>
                <PagosH2>FOA<SpanP>FTTH</SpanP></PagosH2>
                <PagosP>
                <SpanTP>CTA SOLES BBVA:</SpanTP> 0011-0441-0200000380 <br/>
                <SpanTP>CCI SOLES BBVA:</SpanTP> 011-441-000200000380-32
                </PagosP>
            </PagosContent>
            <PagosContent>
                <PagosH2>FOA<SpanP>CORPORATIVOS</SpanP></PagosH2>
                <PagosP>
                <SpanTP>CTA SOLES BBVA:</SpanTP> 0011-0441-0200000380  <br/>
                <SpanTP>CCI SOLES BBVA:</SpanTP> 011-441-000200000380-32  <br/>
                <SpanTP>CTA DOLARES BBVA:</SpanTP> 0011-04410200000399  <br/>
                <SpanTP>CCI DOLARES BBVA:</SpanTP> 011-441-000200000399-32  <br/>
                </PagosP>
            </PagosContent>
            <PagosContent>
                <PagosH2>FOA<SpanP>PYME</SpanP></PagosH2>
                <PagosP>
                    <SpanTP>CTA SOLES BBVA:</SpanTP> 0011-0441-0200000380 <br/>
                    <SpanTP>CCI SOLES BBVA:</SpanTP> 011-441-000200000380-32 <br/>
                    <SpanTP>CTA DOLARES BBVA:</SpanTP> 0011-04410200000399 <br/>
                    <SpanTP>CCI DOLARES BBVA:</SpanTP> 011-441-000200000399-32 <br/>
                </PagosP>
            </PagosContent>
        </PagosWapper>

      </PagosContainer>
    </>
  )
}

export default Pagos;
