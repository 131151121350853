export const aboutObjOne = {
    id: 'vision',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    topLine: 'Nuesta',
    headline: 'Vision',
    description: 'Ser el líder a nivel nacional en la comercialización de tecnologías de comunicación, logrando posicionar nuestros servicios y productos con eficiencia y calidad.',
    buttonLabel: 'Leer Mas',
    imgStart: false,
    img: require("../../images/vision.png"),
    alt: 'Car',   
    dark: true,
    primary: true,
    darkText: false
  };
  
  export const aboutObjTwo ={
      id: 'mision',
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      topLine: 'Nuestra',
      headline: 'Mision',
      description: 'Brindar comunicación de alta calidad accesible a todo nivel, siendo un medio efectivo en beneficio para el desarrollo de la comunidad, con responsabilidad social y respeto por el talento humano.',
      buttonLabel: 'Learn Mas',
      imgStart: true,
      img: require("../../images/mision.png"),
      alt: 'Piggybank',   
      dark: true,
      primary: true,
      darkText: true
  };
  
  export const aboutObjThree ={
      id: 'aboutus',
      lightBg: false,
      lightText: true,
      lightTextDesc: true,
      topLine: '¿Quienes',
      headline: 'Somos?',
      description: 'Somos un operador de telecomunicaciones de capitales peruanos y los únicos en coberturar Iquitos con tecnología 100% fibra óptica. Ofrecemos el mejor servicio de internet a través de tecnología 100% fibra óptica al sector empresarial con provisión de soluciones de internet de alta velocidad,interconexión de sedes, soluciones de seguridad, redes, telefonía IP y otros con lo último en tecnología.',
      buttonLabel: 'Leer Mas',
      imgStart: false,
      img: require('../../images/cfoa.png'),
      alt: 'Paper',   
      dark: true,    
      primary: true,
      darkText: false
  };
