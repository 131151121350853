import styled from "styled-components";
import Porta2 from "../../images/foa17.JPG";

//Estilos de fondo
export const FormContainer = styled.div`
  height: 800px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      rgb(103, 58, 183, 0.7),
      rgb(81, 45, 168, 0.7)
    ),
    url(${Porta2});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  @media screen and (max-width: 768px) {
    height: 1300px;
    align-items: center;
    font-size: 15px;
  }
`;

export const FormTitleH1 = styled.h1`
  color: #fff;
  font-size: 30px;
  text-align: center;
  margin: 20px;
`;

export const FormWrapp = styled.div`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    display: grid;
  }
`;

export const Form = styled.form`
  margin: 10px;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export const ContenCamp = styled.div`
  margin-bottom: 15px;
`;

export const LabelForm = styled.label`
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  color: #1f1f1f;
`;

export const InputForm = styled.input`
  font-family: "Open Sans", sans-serif;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 5px;
  color: #1f1f1f;

  :focus {
    outline: none;
    border: 2px solid #0085ff;
  }
`;

export const TextArea = styled.textarea`
  font-family: "Open Sans", sans-serif;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 5px;
  color: #1f1f1f;

  :focus {
    outline: none;
    border: 2px solid #0085ff;
  }
`;

export const ButtonFrom = styled.button`
  display: block;
  background: #f79e10;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  transition: 0.3s ease all;

  :hover {
    background: #f79e10;
  }
`;

export const FormTextP = styled.div`
  width: 420px;
  align-items: center;
  text-align: justify;
  color: #fff;
  margin: 20px;

  @media screen and (max-width: 768px) {
    align-items: center;
    width: 300px;
    font-size: 10px;
  }
  
`;

export const FormP = styled.p`
    font-size: 25px;
`;

export const IconFrom = styled.a`
  display: flex;
  color: yellow;
  font-size: 84px;
`;

export const ExitoSpan = styled.span`
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
  color: #02a802;
`;

export const SpanError = styled.span`
  color: red;
  font-size: 15px;
  padding-top: 20px;
  margin-top: 0;
`;
