import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { useForm } from "../hook/useForm";
import { FormContainer,
  FormTitleH1,
  Form, IconFrom,
  FormWrapp,
  FormTextP,
  ContenCamp,
  LabelForm,
  InputForm,
  TextArea,
  ButtonFrom,
  ExitoSpan,
  SpanError,
  FormP,
} from "./FormElements";

const initialForm = {
  contact_name: "",
  contact_email: "",
  contact_telf: "",
  contact_asunto: "",
  contact_mensaje: "",
};

const validationsForm = (form) => {
  let errors = {};

  // Validacion de nombre
  if (!form.contact_name.trim()) {
    errors.contact_name = "Campo vacio: es requerido";
  } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(form.contact_name)) {
    errors.contact_name = "El nombre solo puede contener letras y espacio";
  }
  //Validacion de corro
  if (!form.contact_email.trim()) {
    errors.contact_email = "Por favor ingrese Correo";
  } else if (
    !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(form.contact_email)) {
    errors.contact_email = "ERROR: ejemplo@email.com";
  }

  //Validacion de telelfono
  if (!form.contact_telf.trim()) {
    errors.contact_telf = "Por favor ingrese su numero de celular";
  } else if (
    !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{3}$/.test(form.contact_telf)) {
    errors.contact_telf = "Error: Número de celular no permitido ";
  }

  //Validacion de caja de asunto
  if (!form.contact_asunto.trim()) {
    errors.contact_asunto = "Por favor ingrese Asunto";
  } else if (!/^.{1,25}$/.test(form.contact_asunto)) {
    errors.contact_asunto = "No debes exceder los 25 carateres";
  }

  //Validacion de caja de texto
  if (!form.contact_mensaje.trim()) {
    errors.contact_mensaje = "Por favor ingrese texto";
  } else if (!/^.{1,200}$/.test(form.contact_mensaje)) {
    errors.contact_mensaje = "No debes exceder los 200 carateres";
  }

  return errors;
};

const FormOne = () => {
  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm);

  return (
    <>
      <FormContainer>
        <FormWrapp>
        <FormTextP>
            <FormTitleH1>CONTACTA CON NOSOTROS</FormTitleH1>
            <FormP>Déjanos tus datos y un miembro de
            nuestro equipo se comunicará con
            Ud. para absolver sus dudas.
            <br />
            <br />
            Si tiene algún requerimiento extra
            puedes ponerte en contacto con
            nosotros al siguiente correo
            <br />
            <br />
            <strong>Fibra Optica Amazónica</strong></FormP>
            <br />
            <IconFrom> <HiOutlineMail /> </IconFrom>
            <FormP><strong>contacto@foa.com.pe</strong></FormP>
          </FormTextP>

          <Form onSubmit={handleSubmit}>
            <ContenCamp>
              <LabelForm htmlFor="contact_name">Nombre:</LabelForm>
              <InputForm
                type="text"
                name="contact_name"
                placeholder="Tu nombre"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.contact_name}
                required
              />
              {errors.contact_name && (
                <SpanError>{errors.contact_name}</SpanError>
              )}
            </ContenCamp>

            <ContenCamp>
              <LabelForm htmlFor="contact_email">Tu Email:</LabelForm>
              <InputForm
                type="email"
                name="contact_email"
                placeholder="ejemplo@email.com"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.contact_email}
                required
              />
              {errors.contact_email && (
                <SpanError>{errors.contact_email}</SpanError>
              )}
            </ContenCamp>

            <ContenCamp>
              <LabelForm htmlFor="contact_telf">Teléfono:</LabelForm>
              <InputForm
                type="number"
                name="contact_telf"
                placeholder="987654321"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.contact_telf}
                required
              />
              {errors.contact_telf && (
                <SpanError>{errors.contact_telf}</SpanError>
              )}
            </ContenCamp>

            <ContenCamp>
              <LabelForm htmlFor="contact_asunto">Asunto de Correo:</LabelForm>
              <InputForm
                type="text"
                name="contact_asunto"
                placeholder="El asunto del correo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.contact_asunto}
                required
              />
              {errors.contact_asunto && (
                <SpanError>{errors.contact_asunto}</SpanError>
              )}
            </ContenCamp>

            <ContenCamp>
              <LabelForm htmlFor="contact_mensaje">
                Escribe tu Consulta.
              </LabelForm>
              <TextArea
                name="contact_mensaje"
                cols="50"
                rows="5"
                placeholder="Escribe tu mensaje"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.contact_mensaje}
                required
              ></TextArea>
              {errors.contact_mensaje && (
                <SpanError>{errors.contact_mensaje}</SpanError>
              )}
            </ContenCamp>
            <ButtonFrom type="submit" value="Enviar">
              Enviar
            </ButtonFrom>
            {loading && <ExitoSpan>Enviando Correo:</ExitoSpan>}
            {response && <ExitoSpan>Formulario enviado con exito!</ExitoSpan>}
          </Form>
        </FormWrapp>
      </FormContainer>
    </>
  );
};

export default FormOne;
