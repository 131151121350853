import React from 'react';
import Icon1 from '../../images/torre1.png';
import Icon2 from '../../images/satelite1.png';
import Icon3 from '../../images/plato1.png';
import Icon4 from '../../images/mapa.png'
import {
  PanelContainer, 
  Column1, Column2, TextWrapper,
  Title1, Title2,
  ContainerCard,
  PanelCard,
  PanelIcon,
  PanelP,
  PanelImg } from './PanelElements.js'


const PanelSection = () => {
  return (
    <>
        <PanelContainer>
            <Column1>
                <TextWrapper>
                    <Title2>Somos la única empresa en</Title2>
                    <Title1>CONECTAR IQUITOS</Title1>
                    <Title2>a una red 100% Fibra Óptica</Title2>
                </TextWrapper>
                <ContainerCard>
                    <PanelCard>
                        <PanelIcon src={Icon1} />
                        <PanelP>Sin</PanelP>
                        <PanelP>Antenas</PanelP>
                    </PanelCard>
                    <PanelCard>
                        <PanelIcon src={Icon2} />
                        <PanelP>Sin</PanelP>
                        <PanelP>Satelites</PanelP>
                    </PanelCard>
                        <PanelCard>
                        <PanelIcon src={Icon3} />
                        <PanelP>Sin</PanelP>
                        <PanelP>Radio Enlaces</PanelP>
                    </PanelCard>
                </ContainerCard>
            </Column1>
            <Column2>
                <PanelImg src={Icon4} />
            </Column2>
        </PanelContainer>
    </>
  );
}

export default PanelSection;
