import React from 'react';

import {SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SidebarBtnWrap,
    SidebarRoute,} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle} >
      <Icon onClick={toggle} >
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='/' onClick={toggle} >Inicio</SidebarLink>
          <SidebarLink to='/nosotros' onClick={toggle} >Nosotros</SidebarLink>  
          <SidebarLink to='/planes' onClick={toggle} >Planes</SidebarLink>
          <SidebarLink to='/pago_servicios' onClick={toggle} >Forma de Pago</SidebarLink>
          <SidebarLink to='/contactanos' onClick={toggle} >Contactanos</SidebarLink>  
        </SidebarMenu>

        <SidebarBtnWrap>
          <SidebarRoute to='/' >Contactanos</SidebarRoute>
        </SidebarBtnWrap>
      </SidebarWrapper>
      
    </SidebarContainer>
  );
}

export default Sidebar;
