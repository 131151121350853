import React from 'react';
import Footer from '../components/footer/Footer'
import Pagos from '../components/pagos/Pagos';


const PagePagos = () => {
 

  return (
    <>
      <Pagos />
      <Footer />
    </>
  );
}

export default PagePagos;
