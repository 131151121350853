import styled from "styled-components";
import {FaTimes} from 'react-icons/fa';
import {Link as LinkR} from 'react-router-dom';
//import {Link as LinkS} from 'react-scroll';

//Contenedor del Sidebar
export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #42b883;
    display: grid;
    align-items: center; 
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    top: ${({isOpen})=> (isOpen ? '0': '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
    color: #FFF;
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none; 
`;

export const SidebarWrapper = styled.div`
    color: #008B8B;
`;

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px){
        grid-template-rows: repeat(6, 60px);
    }
`;

export const SidebarLink = styled(LinkR)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #F4B118;
    font-size: 30px;
    cursor: pointer;

    &:hover {
        color: #8B008B;
        transition: 0.2s ease-in-out;
    }
`;

//Estilos de contenedor de boton
export const SidebarBtnWrap =styled.div`
    display: flex;
    justify-content: center;
`;

//Estilos del boton 
export const SidebarRoute = styled(LinkR)`
    border-radius: 20px;
    background: #6D14E0;
    white-space: nowrap;
    padding: 16px 64px;
    color: #FFDC1D;
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #F4B118;
        color: #FFF;
        font-size: 20px;
    }
`;