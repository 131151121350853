import styled from "styled-components";
import fondo from '../../images/foa12.jpg';

export const PanelContainer = styled.div`
  justify-content: center;
  background-image:linear-gradient(rgb(103, 58, 183, 0.7),rgb(81, 45, 168, 0.7)), url(${fondo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 750px;
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    height: 1100px;
    display: grid;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;

// EStilos de la columna 1
export const Column1 = styled.div`
  width: 100%;
  float: right;
  margin: 0;
`;
 
// Estilos de Texto Titulos 
export const TextWrapper = styled.div` 
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
`;

export const Title2 = styled.h2`
  margin: 10px;
  font-size: 25px;
  color: #fff;
`;

export const Title1 = styled.h1`
  font-size: 45px;
  color: #fff;
`;

// Estilos de imagenes Card Simple
export const ContainerCard = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: auto;
`;

export const PanelCard = styled.div`
  width: 130px;
  height: auto;
  border-radius: 10px;

`;

export const PanelIcon = styled.img`
  height: 100px;
  width: 100px;
`;

export const PanelP = styled.p`
  color: #fff;
  font-size: 20px;
`;

// Div de imagen mapa
export const Column2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PanelImg = styled.img`
  width: 500px;
  height: 400px;
  @media screen and (max-width: 768px) {
    width: 250px;
    height: 200px;
  }
`;