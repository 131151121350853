import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import PagePagos from './pages/PagePagos';
import PageBlog from './pages/PageBlog';
import PagePlanes from './pages/PagePlanes';
import PageContact from './pages/PageContact';
import {NotFoundPage}  from './pages/NotFoundPage';

import Navbar from './components/navbar/Navbar';
import Sidebar from './components/sidebar/Sidebar';
import { useState } from 'react';
import PreciosPromo from './pages/PreciosPromo';


const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <BrowserRouter>
      <Sidebar isOpen={isOpen} toggle={toggle} /> 
      <Navbar toggle={toggle} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<About />} />
        <Route path='/planes' element={<PagePlanes />} />
        <Route path='/pago_servicios' element={<PagePagos />} />
        <Route path='/pageblog' element={<PageBlog/>}/>
        <Route path='/contactanos' element={<PageContact/>}/>
        <Route path='/precios_promociones' element={<PreciosPromo/>}/>
        <Route path='#' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;


