import React from 'react';

export const NotFoundPage = () => {
  return (
    <div>
        404
        Not Found
    </div>
  );
};
