import React, { useState } from 'react';
import Portada from '../../images/fondo01.jpg';
import { Button } from '../ButtonElements';
import {
  HeroContainer,
  HeroBg,
  ImagBg,
  HeroContent,
  HeroH1,
  HeroH2,
  HeroH3,
  HeroP,
  HeroBtnWrapper,
  ArrowFroward,
  ArrowRight,  } from './HeadElements.js'

const HeadSection = () => {
  const[hover, setHover] = useState(false);

  const onHover = () =>{
    setHover(!hover);
  }

  return (
    <>
      <HeroContainer id='head' >
        <HeroBg>
          <ImagBg src={Portada} />  
        </HeroBg>
        <HeroContent>
          <HeroH2>El único y verdadero internet</HeroH2>
          <HeroH1>100% fibra óptica</HeroH1>
          <HeroH1>De alta velocidad</HeroH1>
          <HeroH3>¡Ya llegó a Iquitos!</HeroH3>
          <br/>
          <br/>
          <HeroH3>¡No te dejes sorprender!</HeroH3>
          <HeroP>Somos el único operador que brinda internet 100%</HeroP>
          <HeroP>Fibra Óptica en la ciudad de Iquitos de principio a</HeroP>
          <HeroP>fin. ¡No utilizamos antenas ni repetidores!.</HeroP>

            <HeroBtnWrapper>
              <Button to='consult' 
                onMouseEnter={onHover} 
                onMouseLeave={onHover} 
                primary='true'
                dark='true'>
                #FibraÓpticaAmazónica {hover ? <ArrowFroward /> : <ArrowRight/>}
              </Button>
            </HeroBtnWrapper>
          </HeroContent>
      </HeroContainer>
    </>
  );
}


export default HeadSection;