import React from 'react';


const PageBlog = () => {


  return (
    <>
      
      <h1>Pagina de blog</h1>
    </>
  );
};

export default PageBlog;
