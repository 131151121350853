import React from 'react'
import DataSection from '../components/datoSection';
import { tarifasPromocion } from '../components/datoSection/Dato';
import Footer from '../components/footer/Footer';

const PreciosPromo = () => {
  return (
    <>
      <DataSection {...tarifasPromocion} />
      <Footer />
    </>
  );
}

export default PreciosPromo;
