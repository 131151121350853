import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  background: linear-gradient(to right, #00093c, #2d0b00);
  width: 100%;
  position: absolute;
  color: #fff;
  padding: 100px 0 30px;
  border-top-left-radius: 125px;
  font-size: 13px;
  line-height: 20px;

  @media screen and (max-width: 700px) {
    bottom: unset;
  }

`;

export const FooterWrap = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`;

export const FooterCol = styled.div`
  flex-basis: 25%;
  padding: 10px;

  :nth-child(2), :nth-child(3){
    flex-basis: 15%;
  }

  @media screen and (max-width: 600px) {
    flex-basis: 100%;
  }
`;

export const FooterImg = styled.img`
  width: 100px;
  margin-bottom: 30px;
  margin-left: 30%;
  
`;

export const FooterP = styled.p`
 color: #fff;
`;

export const FooterPl = styled(Link)`
  text-decoration: none;
  color: #fff;
  display: flex;
  font-size: 12px;
  margin: 2px;
  border-bottom: 2px solid #fff;
  cursor: pointer;
  
  &:hover{
    color: #FFDC1D;
    transform: 0.3s ease-out;
  }
`;

export const FooteH3 = styled.h3`
  width: fit-content;
  margin-bottom: 30px;
  position: relative;
`;


export const FotterConLink = styled.div`
  display: flex;
  flex-direction: column;
`;


export const FooterLink = styled(Link)`
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover{
    color: #FFDC1D;
    transform: 0.3s ease-out;
  }
`;

//Estilos de boton footer correo
export const FooterForm = styled.div`
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px;
`;

export const FooteInput = styled.input`
  width: 100%;
  background-color: transparent;
  color: #ccc;
  border: 0;
  outline: none;
`;

export const IconBtn = styled.button`
  background: transparent;
  color: #fff;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 25px;
`;

export const SocialIcons = styled.div`
  display: flex;
  
`;


export const SocialIconLink = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

// Estilos de barra horizontal 
export const Fhr = styled.hr`
  width: 90%;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 20px auto;
`;

// Estilos de Parrofo copyrait
export const WebSiteTights = styled.p`
  text-align: center;
  color: #fff;
  font-size: 15px;
`;