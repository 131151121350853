import React from 'react'


const PagePlanes = () => {
  

  return (
    <>
      
      <h1>
        Pagina de planes
      </h1>
    </>
  );
}

export default PagePlanes;
