import React from 'react';
import {FaBars} from 'react-icons/fa';
import LogoP from '../../images/foan.png';
import {IconContext} from 'react-icons/lib';
//import {animateScroll as scroll} from 'react-scroll';
import { 
    Nav, 
    NavbarContainer, 
    NavLogo,
    NavLogo1,
    MobileIcon,
    NavMenu,
    NavItems, 
    NavLinks,
    NavBtn,
    NavBtnLink 
    } from './NavbarElements';

const Navbar = ({toggle}) => { 
  
  return (
    <>
      <IconContext.Provider value={{color: '#fff'}}>
        <Nav>
          <NavbarContainer>

            <NavLogo1 to='/'><NavLogo  src={LogoP}></NavLogo></NavLogo1>

            <MobileIcon onClick={toggle} >
              <FaBars />   
            </MobileIcon>
            <NavMenu>
              <NavItems>
                <NavLinks to='/' >Inicio</NavLinks>
              </NavItems>
              <NavItems>
                <NavLinks to='/nosotros' >Nosotros</NavLinks>
              </NavItems>
              <NavItems>
                <NavLinks to='/precios_promociones' >Planes</NavLinks>
              </NavItems>
              <NavItems>
                <NavLinks to='/pago_servicios' >Formas de pago</NavLinks>
              </NavItems>
              <NavItems>
                <NavLinks to='/contactanos' >Contáctanos</NavLinks>
              </NavItems>
                           
            </NavMenu>
            <NavBtn>
              <NavBtnLink to='/contactanos' >Contáctanos</NavBtnLink>
            </NavBtn>
          </NavbarContainer> 
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
