import React from 'react';

import Icons1 from '../../images/vintercon.png';
import Icons2 from '../../images/vinternet.png';
import Icons3 from '../../images/vdata.png';
import Icons4 from '../../images/vsatelital.png';

import {ServiceContainer,
    ServiceH1,
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP, } from './ServiceElements'

const Service = () => {
  return (
    <>
      <ServiceContainer>
          <ServiceH1>Nuestros Servicios</ServiceH1>
          <ServicesWrapper>
            <ServicesCard>
              <ServicesIcon src={Icons1}/>
              <ServicesH2>INTERCONEXION DE SEDES</ServicesH2>
              <ServicesP>Conectamos tus sedes a través de una Red Privada Virtual por medio de Fibra Óptica.</ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icons2}/>
              <ServicesH2>INTERNET DEDICADO</ServicesH2>
              <ServicesP>Servicio de internet de alta velocidad 100% Fibra Óptica de principio a fin.</ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icons3}/>
              <ServicesH2>DATA CENTER Y CLOUD</ServicesH2>
              <ServicesP>Almacenamiento de datos en la nube de forma segura, confiable y flexible.</ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icons4}/>
              <ServicesH2>ENLACE SATELITAL</ServicesH2>
              <ServicesP>Conectamos las sedes de tu empresa que están ubicadas en zonas poco accesibles.</ServicesP>
            </ServicesCard>
          </ServicesWrapper>
      </ServiceContainer>
    </>
  );
}

export default Service;
