import React from 'react';
import {PriceContainer, 
    PriceH1, PriceWrapper,
    PriceCards,
    PriceCircle,
    PriceH2,
    PriceH3,
    PriceContent,
    PriceP,
    PriceLink,
    Pspan, } from './PriceElements';

const PriceSection = () => {
  return (
    <>
        <PriceContainer>
            <PriceH1>Planes</PriceH1>
            <PriceWrapper>
                <PriceCards>
                    <PriceCircle>
                        <PriceH2>15</PriceH2>
                        <PriceH3>Mbps</PriceH3>
                    </PriceCircle>
                    <PriceContent>
                        <PriceP>A tan solo</PriceP>
                        <PriceLink>149.<Pspan>00</Pspan></PriceLink>
                    </PriceContent>
                </PriceCards>
                <PriceCards>
                    <PriceCircle>
                        <PriceH2>15</PriceH2>
                        <PriceH3>Mbps</PriceH3>
                    </PriceCircle>
                    <PriceContent>
                        <PriceP>A tan solo</PriceP>
                        <PriceLink>149.<Pspan>00</Pspan></PriceLink>
                    </PriceContent>
                </PriceCards>
                <PriceCards>
                    <PriceCircle>
                        <PriceH2>15</PriceH2>
                        <PriceH3>Mbps</PriceH3>
                    </PriceCircle>
                    <PriceContent>
                        <PriceP>A tan solo</PriceP>
                        <PriceLink>149.<Pspan>00</Pspan></PriceLink>
                    </PriceContent>
                </PriceCards>
            </PriceWrapper>
        </PriceContainer>
    </>
  )
}

export default PriceSection;
