import React from 'react';

import Footer from '../components/footer/Footer';
import HeadSection from '../components/headSection/HeadSection';
import PanelSection from '../components/panelSection/PanelSection';
import PriceSection from '../components/sectionPrecies/PriceSection';
import SectionValor from '../components/sectionValor/SectionValor';
import Service from '../components/services/Service';

const Home = () => {

  return (
    <>
      <HeadSection />
      <PanelSection /> 
      <SectionValor />
      <PriceSection />
      <Service />
      <Footer />
    </>
  );
}

export default Home;
