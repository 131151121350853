import styled from "styled-components";
import Porta2 from '../../images/foa02.JPG'

// Estilos de fondo
export const PriceContainer = styled.div`
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    background-image:linear-gradient(rgb(103, 58, 183, 0.7),rgb(81, 45, 168, 0.7)), url(${Porta2});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    @media screen and (max-width: 768px) {
        height: 1300px;
    }

    @media screen and (max-width: 480px) {
        height: 1300px;
    }
`;

export const PriceH1 = styled.h1`
    margin-top: 10px;
    font-size: 80px;
    color: #fff;
`;

export const PriceWrapper = styled.div`
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 20px;
    padding: 0 30px;

    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 10px;
    }
`;

//Stilos Cards
export const PriceCards = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 250px;
    height: 340px;
    background: #F4B118;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
`;

export const PriceCircle = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #6D14E0;
    clip-path: circle(180px at center 0);
    text-align: center;
`;

export const PriceH2 = styled.h2`
    color: #fff;
    font-size: 3.5em;
    padding: 15px 0;
`;

export const PriceH3 = styled.h3`
    color: #fff;
    font-size: 2.5em;
    padding: 10px 0;
`;

export const PriceContent = styled.div`
    position: absolute;
    bottom: 10px;
    padding: 20px;
    text-align: center;
`;

export const PriceP = styled.p`
    color: #fff;
    font-size: 35px;
`;

export const PriceLink = styled.a`
    font-size: 25px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    background: #6D14E0;
    color: #fff;
    border-radius: 40px;
    text-decoration: none;
    margin-top: 10px;
`;

export const Pspan = styled.span`
    font-size: 15px;
`;