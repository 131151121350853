import React from 'react';

import {BsTelephone} from "react-icons/bs"
import {GoMail} from "react-icons/go";

import { 
  ContentContact,
  ContectInfo,
  Titheh2,
  Icons,
  ContectSuport,
  DescriP, P,

 } from './ElmentesSuport';

const DatosSuport = () => {
  return (
    <>
      <ContentContact>
        <ContectInfo>
          <Titheh2>Soporte Técnico</Titheh2>
          <ContectSuport>
            <Icons><BsTelephone/></Icons> 
            <DescriP>
              <P>Central-Soporte Técnico:</P>
              <P>Tel: +51 99 060 606</P>
              <P>Tel: 0800 8 0800</P>
            </DescriP>
          </ContectSuport>
          <ContectSuport>
          <Icons><GoMail/></Icons> 
            <DescriP>
              <P>soporte.corporativo@globalfiber.com.pe</P>
            </DescriP>
          </ContectSuport>
        </ContectInfo>
        <ContectInfo>
        <ContectInfo>
          <Titheh2>Informacion Comercial</Titheh2>
          <ContectSuport>
          <Icons><BsTelephone/></Icons> 
            <DescriP>
              <P>Ventas Corporativas</P>
              <P>+51 966 549 169</P>
            </DescriP>
          </ContectSuport>
          <ContectSuport>
          <Icons><BsTelephone/></Icons> 
            <DescriP>
              <P>Ventas Masivas - Hogar</P>
              <P>+51 938 326 319</P>
            </DescriP>
          </ContectSuport>
        </ContectInfo>
        </ContectInfo>
      </ContentContact>
    </>
  )
};

export default DatosSuport;