export const tarifasPromocion ={
    id: 'tarifas',
    lightBg: true,
    lightText: true,
    lightTextDesc: false,
    topLine: 'Revisa Nuestras',
    headline: 'Tarifas y Promociones',
    description: 'Pronto tendremos más promociones:  Dele "click" en ver',
    buttonLabel: 'Ver',
    imgStart: true,
    img: require('../../images/cfoa.png'),
    alt: 'Paper',  
    dark: true,    
    primary: true,
    darkText: true
};