import styled from "styled-components";

export const ContentContact = styled.div`
  height: 300px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  weight:100%;

  @media (max-width: 778px) {
   display: flex;
   flex-direction: column;
   height: 450px;
  }
`;

export const ContectInfo = styled.div`
  margin: 0 20px;
  weight:100%;
`;

export const Titheh2 = styled.h1`
  color: #f79e10;
  text-align: center;
`;

export const Icons = styled.p`
  font-size: 50px;
  color: #BC0E92;

  @media (max-width: 820px) {
    font-size: 35px;
  }
`;

export const ContectSuport = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  @media (max-width: 778px) {
   justify-content: center;
  }
`;

export const DescriP = styled.div`
  margin: 10px;
`;

export const P = styled.p`
  font-size: 20px;

  @media (max-width: 460px) {
   font-size: 15px;
  }
`;